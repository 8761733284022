import React, { useEffect, useState } from 'react'
import styles from './Navbar.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

function Navbar() {
    const [isActive, setIsActive] = useState(false)
    const [scrolled, setScrolled] = useState(false);

    const handleBtn = () => {
        setIsActive((current) => !current);
    }

    const handleScroll = () => {
        const offset = window.scrollY;

        if (offset > 200) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    return (
        <nav className={cx('navbar', scrolled && 'box-shadow')}>
            <div className={cx('container')}>
                <div
                    className={cx('bg-dark')}
                    style={{
                        display: isActive ? 'block' : 'none'
                    }}
                ></div>
                <div className={cx('logo')}
                    onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }}
                >
                    <img src='/imgs/mail/logo.svg' alt="logo"></img>
                    <h2>Email: AI Email, Mail Cleaner</h2>
                </div>
                <div
                    className={cx('navbar-right', isActive && 'active')}
                >
                    <ul>
                        <li><a href='/'>Home</a></li>
                        <li><a href='#contact-us' onclick="smoothScroll(document.getElementById('contact-us'))">Contact us</a></li>
                        <li><a href='https://starnestsolution.com/calendar/androidprivatepolicy'>Private Policy</a></li>
                    </ul>
                    <button>
                        <a href="https://play.google.com/store/apps/details?id=com.starnest.mail">FREE DOWNLOAD</a>
                    </button>
                </div>
                <div className={cx('menu')} onClick={handleBtn}>
                    <img src='/imgs/mail/icons/menu.svg' alt='menu' />
                </div>
            </div>
        </nav>
    )
}

export default Navbar