import React from 'react'
import styles from './Banner.module.scss'
import classNames from 'classnames/bind';

const cx = classNames.bind(styles)

function Banner() {
    return (
        <section className={cx('banner')}>
            <img src='/imgs/mail/logo.svg' alt='' className={cx('logo')} />
            <div className={cx('content')}>
                <h1>Email: AI Email, Mail Cleaner</h1>
                <h4>
                    The ultimate digital planner for organizing your life, reaching your goals &  achieving productivity
                </h4>
            </div>
            <div className={cx('actions')}>
                <button>
                    <a href="https://play.google.com/store/apps/details?id=com.starnest.mail">FREE DOWNLOAD</a>
                </button>
                <div className={cx('social')}>
                    <p>Follow Me</p>
                    <div className={cx('social-icons')}>
                        <img src='/imgs/mail/icons/fb.svg' alt="facebook" />
                        <img src='/imgs/mail/icons/insta.svg' alt="instagram" />
                        <img src='/imgs/mail/icons/youtube.svg' alt="youtube" />
                        <img src='/imgs/mail/icons/tiktok.svg' alt="tiktok" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner